<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Images</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Images"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-btn depressed light :to="{ name: 'module-drum-admin-images' }">
            Return to active list
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-for="image in images" v-bind:key="image.id">
          <v-card>
            <v-img
              :src="image.asset_urls.thumbnail"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
            >
              <v-card-title>{{ image.name }}</v-card-title>
              <v-card-subtitle class="white--text">{{
                image.categories
                  .map(c => {
                    return c.name;
                  })
                  .join(", ")
              }}</v-card-subtitle>
            </v-img>
            <v-card-actions>
              <v-btn icon color="yellow" @click="openRestore(image)">
                <v-icon>mdi-restore</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Image</v-card-title>
        <v-card-text>Are you sure you want to restore this image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Admin",
          disabled: true,
        },
        {
          text: "Images",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-admin-images",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        image: {},
      },
    };
  },

  computed: {
    images() {
      let images = this.$store.state.drum.admin["archivedImages"];

      if (this.searchTerm !== "") {
        images = images.filter(i => {
          const name = i.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return images;
    },
  },

  methods: {
    openRestore(image) {
      this.restoreDialog.image = image;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.image = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("drum/admin/restoreImage", {
          appId,
          imageId: this.restoreDialog.image.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
